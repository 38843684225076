
import { mapState, mapMutations } from "vuex"
export default {
  name: "DatePicker",
  props: {
    chosenDate: {
      type: [Object, Boolean],
      default: false,
      required: false,
    },
    deliveryDays: {
      type: [Array, Boolean],
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: String,
      required: false,
      default: "21.4%",
    },
  },
  computed: {
    ...mapState(["showCalendar", "showSearch"]),
  },
  methods: {
    ...mapMutations({ setCalendarStatus: "SET_CALENDAR_STATUS" }),
    toggleCalendar() {
      this.setCalendarStatus(!this.showCalendar)
      this.$emit("openedCalendar", this.showCalendar)
    },
    returnDate(date) {
      this.$emit("returnDate", date)
      this.$emit("openedCalendar", false)
    },
  },
}
