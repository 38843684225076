
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"

export default {
  name: "Default",
  data: () => ({
    imageUrl: process.env.IMAGE_URL,
  }),
  computed: {
    ...mapGetters(["cartItemCount"]),
    ...mapState([
      "chosenMaker",
      "authenticated",
      "chosenDiscount",
      "templateLoading",
      "rewards",
      "makerCategories",
      "cart",
      "cuisines",
      "chosenProduct",
      "showCart",
      "user",
      "reviews",
    ]),
  },
  watch: {
    authenticated(to) {
      to && this.getCustomer()
    },
    $route(from, to) {
      if (to.name !== from.name) {
        if (to.name !== "search" || to.name !== "index") {
          this.deepFilter(false)
        }
        this.setCartStatus(false)
        this.setSearchStatus(false)
      }
    },
  },
  mounted() {
    !this.makerCategories && this.getMakerCategories()
    !this.reviews && this.getReviews()
    this.setWindowSize(window.innerWidth)
    window.addEventListener("resize", this.handleResize)
    this.init()
    this.$route.query.discount &&
      this.setChosenDiscount({
        discount_code: this.$route.query.discount
          .toUpperCase()
          .replace(/\s+/g, ""),
      })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    ...mapMutations({
      setCustomer: "SET_CUSTOMER",
      setDeliveryAddress: "SET_DELIVERY_ADDRESS",
      setAuthenticated: "SET_AUTHENTICATED",
      setCart: "SET_CART",
      setOrder: "SET_ORDER",
      setChosenDiscount: "SET_CHOSEN_DISCOUNT",
      setWindowSize: "SET_WINDOW_SIZE",
      setSearchStatus: "SET_SEARCH_STATUS",
      setCartStatus: "SET_CART_STATUS",
      setDealId: "SET_DEAL_ID",
    }),
    ...mapActions([
      "getMakerCategories",
      "getMakerBySlug",
      "addToCart",
      "getShareCart",
      "deepFilter",
      "getCustomer",
      "getReviews",
    ]),
    init() {
      if (this.$route.query.loginAs) {
        this.setAuthenticated(JSON.parse(this.$route.query.loginAs))
        this.$router.push("/account")
      } else {
        this.setAuthenticated(
          JSON.parse(localStorage.getItem("authenticated") || false)
        )
        this.setCustomer(JSON.parse(localStorage.getItem("customer") || false))
      }
      this.setDeliveryAddress(
        JSON.parse(localStorage.getItem("address") || false)
      )
      if (this.$route.query.deal_id) {
        this.setDealId(this.$route.query.deal_id)
      }
      if (this.$route.query.cart) {
        this.getShareCart(this.$route.query.cart).then((data) => {
          const currentCart = JSON.parse(data.data.cart)
          this.getMakerBySlug({
            slug: currentCart.maker.slug,
            makerPage: false,
          }).then(() => {
            this.addToCart({
              ...currentCart,
              maker: this.chosenMaker,
            })
          })
        })
      } else {
        this.setCart(JSON.parse(localStorage.getItem("and-dine-cart") || false))
      }
    },
    handleResize() {
      this.setWindowSize(window.innerWidth)
    },
    goToMaker(val) {
      if (this.cartItemCount > 0 && val) {
        this.$route.name === "store-slug"
          ? window.scrollTo({ top: 600, behavior: "smooth" })
          : this.$router.push(`/store/${this.cart.maker.slug}`)
      }
    },
  },
  head() {
    if (this.$route.query.product && this.chosenProduct) {
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org",
              "@type": "Product",
              productId: this.$state.chosenProduct?.id,
              name: this.$state.chosenProduct?.name,
              description: this.$state.chosenProduct?.description,
              url: `https://anddine.co.uk/store/${this.$route.params.slug}/product/${this.$route.query.product}`,
              image: this.$state.chosenProduct?.image,
              offers: {
                "@type": "Offer",
                availability: "https://schema.org/InStock",
                price: this.$state.chosenProduct?.price,
                priceCurrency: "GBP",
              },
            },
          },
        ],
      }
    }
    if (this.$route.name === "store-slug" && this.chosenMaker) {
      return {
        link: [
          {
            rel: "canonical",
            href: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
        ],
        title: `${this.$route.params.slug
          .replace("-and-", "-&-")
          .replace(/-/g, " ")
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
        meta: [
          {
            hid: "description",
            name: "description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "twitter:url",
            name: "twitter:url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "twitter:title",
            name: "twitter:title",
            content: `${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
          },
          {
            hid: "twitter:description",
            name: "twitter:description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "twitter:image",
            name: "twitter:image",
            content: `https://api.anddine.co.uk/uploads/maker/${this.chosenMaker.id}/logo/600x600-logo.png`,
          },
          {
            hid: "og:site_name",
            name: "og:site_name",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          { hid: "og:type", name: "og:type", content: "website" },
          {
            hid: "og:url",
            name: "og:url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "og:title",
            name: "og:title",
            content: `${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())} from &Dine`,
          },
          {
            hid: "og:description",
            name: "og:description",
            content: `Order from ${this.$route.params.slug
              .replace("-and-", "-&-")
              .replace(/-/g, " ")
              .replace(/(?:^|\s)\S/g, (a) =>
                a.toUpperCase()
              )}. Delivered by &Dine. Discover real flavours. Support independent Makers`,
          },
          {
            hid: "og:image",
            name: "og:image",
            content: `https://api.anddine.co.uk/uploads/Maker/${this.chosenMaker.id}/profile/200x200-profile.webp`,
          },
          {
            hid: "og:image:secure_url",
            name: "og:image:secure_url",
            content: `https://anddine.co.uk/store/${this.$route.params.slug}`,
          },
          {
            hid: "og:image:alt",
            name: "og:image:alt",
            content: this.$route.params.slug,
          },
        ],
      }
    }
  },
}
