
export default {
  name: "TimePicker",
  props: {
    cart: {
      type: [Object, Boolean],
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    showTimes: false,
    minutes: [
      { name: "00", value: "00" },
      { name: "15", value: "15" },
      { name: "30", value: "30" },
      { name: "45", value: "45" },
    ],
    chosenHour: false,
    chosenMinute: false,
  }),
  computed: {
    hasWindows() {
      return this.cart?.date?.deliveryWindows?.length > 0
    },
    hasSelected() {
      return this.cart.date?.selectedWindow
    },
    hasDate() {
      return this.cart.date
    },
    hours() {
      return this.cart?.date?.deliveryWindows
    },
    timeSelected() {
      return !!this.chosenHour && !!this.chosenMinute
    },
  },
  methods: {
    toggleTimes() {
      this.showTimes = !this.showTimes
    },
    returnTime() {
      const time = `${this.chosenHour}:${this.chosenMinute}`
      this.showTimes = false
      this.$emit("returnTime", time)
    },
  },
}
