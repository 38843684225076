
export default {
  name: "QuantityOperator",
  props: {
    productQuantity: {
      type: Number,
      required: true,
      default: 1,
    },
    item: {
      type: Object,
      default: () => {},
      required: false,
    },
    modifiers: {
      type: Array,
      default: () => [],
      required: false,
    },
    minQuantity: {
      type: Number,
      default: 1,
      required: false,
    },
    maxQuantity: {
      type: Number,
      default: 100,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBin: {
      type: Boolean,
      default: true,
      required: false,
    },
    disableMinus: {
      type: Boolean,
      default: false,
      required: false,
    },
    picker: {
      type: Boolean,
      default: false,
      required: false,
    },
    input: {
      type: Boolean,
      default: false,
      required: false,
    },
    vertical: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    quantity: 0,
    showPicker: false,
  }),
  computed: {
    isMax() {
      return this.maxQuantity <= 0
    },
    isMin() {
      return this.quantity <= this.minQuantity
    },
  },
  watch: {
    productQuantity(to) {
      this.quantity = to
    },
    quantity(to) {
      this.quantity = parseInt(to)
    },
  },
  mounted() {
    this.quantity = this.productQuantity
  },
  methods: {
    changeQuantity(plus) {
      if (this.item?.modifierGroups && this.item?.modifierGroups.length > 0) {
        this.$emit("returnQuantity", {
          product: { ...this.item, quantity: this.quantity },
          appliedModifiers: this.modifiers,
        })
      } else {
        !plus && (this.quantity -= 1)
        plus &&
          (this.quantity >= this.minQuantity
            ? (this.quantity += 1)
            : (this.quantity = this.minQuantity))
        this.$emit("returnQuantity", {
          product: { ...this.item, quantity: this.quantity },
          appliedModifiers: this.modifiers,
        })
      }
    },
    updateScrollQuantity() {
      this.showPicker = false
      this.$emit("returnQuantity", {
        product: { ...this.item, quantity: this.quantity },
        appliedModifiers: this.modifiers,
      })
    },
    updateInputQuantity() {
      setTimeout(() => {
        if (!this.quantity) this.quantity = 10
        if (this.minQuantity > 0 && this.quantity < this.minQuantity)
          this.quantity = this.minQuantity
        if (this.quantity > this.maxQuantity) this.quantity = this.maxQuantity

        this.$emit("returnQuantity", {
          product: { ...this.item, quantity: this.quantity },
          appliedModifiers: this.modifiers,
        })
      }, 500)
    },
  },
}
