
import { mapState, mapActions } from "vuex"

export default {
  name: "SearchBar",
  data: () => ({
    searchTerm: "",
    andDineApi: process.env.AND_DINE_API,
    imageUrl: process.env.IMAGE_URL,
    timeout: null,
  }),
  computed: {
    ...mapState(["searchResults"]),
    showResults() {
      return (
        this.searchResults &&
        this.searchResults.length > 0 &&
        this.searchTerm !== ""
      )
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.clearSearch()
      }
    },
  },
  methods: {
    ...mapActions(["deepSearch"]),
    returnSearch() {
      if (this.searchTerm === "") {
        this.$emit("returnSearch", false)
        this.deepSearch(false)
      } else {
        this.deepSearch(this.searchTerm)
      }
    },
    clearSearch() {
      this.searchTerm = ""
      this.$emit("returnSearch", false)
      this.deepSearch(false)
    },
  },
}
