
import { mapActions, mapState, mapMutations } from "vuex"
import copy from "copy-to-clipboard"

export default {
  name: "ShoppingCart",
  props: {
    disableInputs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    andDineApi: process.env.AND_DINE_API,
    showWindows: false,
    showDeliveryTooltip: false,
    showCalendar: false,
    shareLink: false,
  }),
  computed: {
    ...mapState([
      "customerRole",
      "customer",
      "cart",
      "order",
      "deliveryFilter",
      "isBiz",
      "cartError",
      "user",
      "dealId",
      "chosenDeal",
      "showCart",
    ]),
    isUserSales() {
      return this.user.role === "sales"
    },
    belowMinAmount() {
      return (
        this.minOrderVal >
        parseFloat(
          (this.order?.food_total || this.cart.totalVatFree) / 100
        ).toFixed(2)
      )
    },
    showCartItems() {
      return this?.cart?.items && this.cart.items.length > 0
    },
    freeDeliveryReached() {
      return (
        parseFloat(this.cart.subtotal) >=
        parseFloat(this.cart.maker.free_delivery_min_amount)
      )
    },
    minOrderVal() {
      return this.isBiz ? this.cart.maker.min_order_amount / 100 || 15 : 15
    },
    isCheckout() {
      return this.$route.name === "checkout"
    },
    localDelivery() {
      return this.cart.totalVatFree >= 40000
        ? this.cart.totalVatFree >= 200000
          ? 6500
          : 5000
        : 3500
    },
    localVAT() {
      return this.cart.vatTotal + this.localDelivery * 0.2
    },
  },
  methods: {
    ...mapActions([
      "removeFromCart",
      "addToCart",
      "createShareCart",
      "validateOrder",
    ]),
    ...mapMutations({
      setCartStatus: "SET_CART_STATUS",
      setDeliveryDate: "SET_DELIVERY_DATE",
      setSelectedWindow: "SET_SELECTED_WINDOW",
      setDeliveryWindows: "SET_DELIVERY_WINDOWS",
    }),
    goToCheckout() {
      this.$router.push("/checkout")
      this.setCartStatus(false)
    },
    generateGroupOrder() {
      alert("generateGroupOrder")
    },
    updateShareLink() {
      this.shareLink = false
      this.createShareCart(this.dealId).then((response) => {
        const shareLink = encodeURI(
          `${process.env.MAIN_URL}store/${this.cart.maker.slug}/?cart=${response.data.id}`
        )
        this.shareLink = true
        copy(shareLink)
      })
    },
    updateCartQuantity(payload) {
      const updatedItems = this?.cart?.items || []
      const currentItem = updatedItems.find(
        (item) =>
          item.product.id === payload.product.id &&
          item.appliedModifiers.every(
            (modifier, index) =>
              modifier.modifier_id ===
              payload.appliedModifiers[index].modifier_id
          )
      )
      if (
        payload.product.quantity >= payload.product.min_order_amount ||
        !payload.product.min_order_amount
      ) {
        this.addToCart({
          ...this.cart,
          product: currentItem.product,
          quantity: payload.product.quantity,
          appliedModifiers: currentItem.appliedModifiers,
          date: this.cart?.date || false,
        })
      } else {
        this.removeCartItem(payload)
      }
    },
    removeCartItem(item) {
      this.removeFromCart({
        product: item.product,
        quantity: 0,
        appliedModifiers: item.appliedModifiers,
        maker: this.cart.maker,
        date: this.cart?.date || false,
      })
    },
    chooseDeliveryDate(date) {
      this.setDeliveryDate(date)
      this.setDeliveryWindows(date)
    },
    chooseDeliveryWindow(window) {
      this.setSelectedWindow(window)
      this.validateOrder(this.cart)
    },
  },
}
